import React, { useState, useRef, useEffect, useContext } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';

import { useCart } from "../context/CartContext"
import { routes } from '../routes';
import { Input } from 'antd';
import { MoreIcon, PhoneIcon, UserSelectedIcon, LogoutIcon, UserIcon, SavedItems } from "../assets/icons/hiking";
import { EmptyCart, OneProductCart, TwoProductCart, ThreeProductCart, FourProductCart, FiveProductCart, SixProductCart, SevenProductCart, EightProductCart, NineProductCart, MoreProductCart } from '../assets/icons/cart-icons';
import "react-toastify/dist/ReactToastify.css";

import { SearchContext } from '../context/SearchContext';

import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import GavelIcon from '@mui/icons-material/Gavel';
import InventoryIcon from '@mui/icons-material/Inventory';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';

import trcOne from '../assets/images/trc-one.png'


export default function Main() {

  // use the imported routes  {/app*} from routes.js
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const { getTotalProducts } = useCart();
  const { setSearchTerm } = useContext(SearchContext);

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  const selectCartIcon = () => {
    const totalProducts = getTotalProducts();

    switch (totalProducts) {
      case 0: return <EmptyCart />;
      case 1: return <OneProductCart />;
      case 2: return <TwoProductCart />;
      case 3: return <ThreeProductCart />;
      case 4: return <FourProductCart />;
      case 5: return <FiveProductCart />;
      case 6: return <SixProductCart />;
      case 7: return <SevenProductCart />;
      case 8: return <EightProductCart />;
      case 9: return <NineProductCart />;
      default: return <MoreProductCart />;
    }
  };

  const { Search } = Input;

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const icons = [
    <AccountCircleIcon />,
    <HomeIcon />,
    <InventoryIcon />,
    <ContactMailIcon />,
    <BookmarkAddedIcon />,
    <GavelIcon />,
    <LogoutRoundedIcon />,
  ];

  const actions = {
    'Home': () => navigate('/app/home'),
    'Explore Products': () => navigate('/app/products'),
    'Saved Products': () => navigate('/app/saved'),
    'Terms & Conditions': () => navigate('/app/tos'),
    'Log Out': logout,
  };


  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
        <ListItem disablePadding>
          <ListItemText
            primary={
              <Typography variant="h6" align="center">
                {localStorage.getItem("username")}
              </Typography>
            }
          />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText
            primary={
              <Typography variant="body2" align="center">
                {localStorage.getItem("username")}@gmail.com
              </Typography>
            }
          />
        </ListItem>
      </List>
      <Divider />

      <List>
        {['My Account', 'Home', 'Explore Products', 'Contact TRC', 'Saved Products', 'Terms & Conditions', 'Log Out'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton
              onClick={actions[text] || null}
            >
              <ListItemIcon>
                {icons[index]}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

    </Box>
  );



  const handleMoreIconClick = () => {
    if (window.innerWidth < 640) {
      setOpen(true);
      console.log("this is responsive")
    } else {
      navigate('products');
    }
  };

  // Logout functions
  function clearCookies() {
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .trim()
        .split("=")[0]
        .concat("=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/");
    });
  }

  function clearLocalStorage() {
    localStorage.clear();
  }
  function logout() {
    clearCookies();
    clearLocalStorage();
    navigate("/login");
  }


  return (
    <div className="flex flex-col w-full mx-auto">

      {/* Black subheader */}
      <div className="w-full bg-black text-white hidden sm:block sticky-container"
        style={{ height: '31px', position: 'fixed', top: '0', left: '0', zIndex: '100', width: '100%' }}>
        <div className="flex items-center justify-between h-full px-6">
          <p className="text-xs">Marketing Works  Currency:  Euro (EUR) </p>
          <p className="text-xs">Customer: 100000175 - ECOMMERCE ORDER</p>
        </div>
      </div>

      {/* Header */}
      <div className="w-full" style={{ position: 'fixed', top: '31px', left: '0', zIndex: '99', width: '100%' }}>
        <div className="flex items-center justify-between w-full h-20 bg-white px-6 shadow-b">

          <div className="flex w-full sm:w-auto">

            <div
              className="cursor-pointer z-10 order-2 sm:order-1 mx-auto sm:mx-0"
              onClick={() => navigate('home')}
            >
              <img className="h-12 mr-4 sm:mr-0" src={trcOne} alt="Logo" />
            </div>

            <div className="flex items-center space-x-4 px-3 order-1 sm:order-2">
              <div onClick={handleMoreIconClick} className="flex items-center cursor-pointer z-10">
                <MoreIcon className="mr-2" />
                <span className="px-3 hidden sm:inline">Explore</span>
              </div>
            </div>
          </div>

          <div className="flex flex-1 items-center hidden sm:inline">

            <Search
              className="reset-ant-input"
              placeholder="Search a catalog or Product..."
              allowClear
              onSearch={handleSearch}
              style={{
                width: '100%',
              }}
              size="large"
            />

          </div>

          <div className="flex items-center space-x-3 px-4 hidden sm:flex">
            <PhoneIcon className="text-xl" />
            <div className="flex flex-col">
              <span className="text-xs">Need Help?</span>
              <span className="font-bold text-xs">+1368748734</span>
            </div>
          </div>


          <div className="flex items-center space-x-4 px-3 hidden sm:flex">
            <div className="relative">
              <div onClick={toggleMenu} className="cursor-pointer">
                {isMenuOpen ? <UserSelectedIcon /> : <UserIcon />}
              </div>

              {isMenuOpen && (
                <div ref={menuRef} className="absolute z-50 mt-2 bg-white shadow-md border border-gray-200 rounded-md left-0 transform -translate-x-full" style={{ width: '264px', height: '227px', transform: 'translateX(-100%)' }}>
                  <ul style={{ paddingBottom: '0' }}>
                    <li className="px-4 py-2 border-b border-gray-200" style={{ fontWeight: '500' }}>
                      <div>{localStorage.getItem("username")}</div>
                      <div style={{ fontSize: 'small', fontWeight: '500' }}>email@example.com</div>
                    </li>
                    <li
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer border-b border-gray-200"
                      style={{ fontWeight: '500' }}
                      onClick={() => { closeMenu(); }}
                    >
                      My account
                    </li>
                    <li
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer border-b border-gray-200"
                      style={{ fontWeight: '500' }}
                      onClick={() => { closeMenu(); }}
                    >
                      Contact TRC
                    </li>
                    <li
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer border-b border-gray-200"
                      style={{ fontWeight: '500' }}
                      onClick={() => {
                        closeMenu();
                        navigate('tos');
                      }}
                    >
                      Terms & Conditions
                    </li>
                    <li
                      className="flex justify-between items-center px-4 py-2 hover:bg-gray-100 cursor-pointer border-b border-gray-200"
                      onClick={() => {
                        closeMenu();
                        logout();
                      }}
                      style={{ fontWeight: '500' }}
                    >
                      <span>Log out</span>
                      <LogoutIcon className="text-xl" />
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>


          <div className="flex items-center space-x-4 px-3 hidden sm:inline">
            <Tooltip title="Saved Items" placement="top">
              <div onClick={() => navigate('saved')} className="cursor-pointer">
                <SavedItems />
              </div>
            </Tooltip>
          </div>

          <div className="flex items-center space-x-4 px-3">
            <Tooltip title="Cart" placement="top">
              <div className="cursor-pointer" onClick={() => navigate('cart')}>
                {selectCartIcon()}
              </div>
            </Tooltip>
          </div>

        </div>
      </div>


      <div>
        <Drawer open={open} onClose={toggleDrawer(false)}>
          {DrawerList}
        </Drawer>
      </div>

      <div className="flex flex-1 items-center block sm:hidden md:hidden lg:hidden xl:hidden z-10">
        <input
          type="text"
          placeholder="Search a catalog or Product..."
          className="input-tokens w-full"
        />
      </div>



      <div className="w-full text-gray-800">
        <Routes>
          {Object.entries(routes).map(([path, Component]) => (
            <Route key={path} path={path.replace('/app', '')} element={<Component />} />
          ))}
          <Route path="*" element={<div className="fixed inset-0 flex items-center justify-center">Nothing Found from 'header'</div>} />
        </Routes>
      </div>



    </div >
  );




}




