import React from "react";
import "./assets/styles/main.css";
import { Routes, Route, BrowserRouter } from "react-router-dom"; // Import BrowserRouter here
import AdminRouter from "./components/header";
import Login from "./pages/login";
import { PanelStyleProvider } from './context/PanelStyle';
import { NotificationProvider } from './context/NotificationContext';
import { SearchProvider } from './context/SearchContext';
import { CartProvider } from './context/CartContext';
import { Redirect } from "./hooks/logout";
import ProtectedRoute from "./components/ProtectedRoute";
import Testing from './pages/dev/testing'

function App() {
  return (
    <BrowserRouter>
      <NotificationProvider>
        <PanelStyleProvider>
          <SearchProvider>
            <CartProvider>
              <div className="h-full">
                <div className="flex justify-center w-full h-full mx-auto">
                  <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/testing" element={<Testing />} />

                    <Route path="/login" element={<Login />} />
                    <Route path="/logout" element={<Redirect />} />

                    {/* Protect the /app/* route */}
                    <Route
                      path="/app/*"
                      element={
                        <ProtectedRoute>
                          <AdminRouter />
                        </ProtectedRoute>
                      }
                    />

                    <Route path="*" element={<div>Nothing Found</div>} />
                  </Routes>
                </div>
              </div>
            </CartProvider>
          </SearchProvider>
        </PanelStyleProvider>
      </NotificationProvider>
    </BrowserRouter>
  );
}

export default App;
